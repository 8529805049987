<template>
    <div>
        <van-nav-bar
          title="联系人信息"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        />
        <div class="height-10"></div>
        <van-cell-group :border="false">
            <van-cell :border="false" class="padding-t-b-5">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <div class="custom-title font-16"><div class="left-title  font-16">项目编号:</div><span>{{datas.projectitemcode}}</span></div>
              </template>
            </van-cell>
            <van-cell :border="false" class="padding-t-b-5">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <div class="custom-title font-16"><div class="left-title  font-16">项目名称:</div><span class="weight-blod">{{datas.projectitemname}}</span></div>
              </template>
            </van-cell>

            <van-form @submit="onSubmit">
                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">用于调查的项目简称<span class="red">*</span></div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" maxlength="20" v-model="datas.short_name" :rules="[{required:true, message:'20字以内,一个英文字母或数字都算一个字' }]"/>

                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">被调查人员姓名(甲方项目经理及以上)<span class="red">*</span></div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" v-model="datas.name" :rules="[{required:true, message:'请输入姓名' }]"/>

                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">职位<span class="red">*</span></div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" v-model="datas.position" :rules="[{required:true, message:'请输入职位'}]"/>

                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">电话<span class="red">*</span></div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" v-model="datas.mobile" :rules="[{required: true, message:'请输入电话'},{pattern:/^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|19[0-9]|18[0-9]|14[57])[0-9]{8}$/, message:'请输入正确的电话'}]"/>

                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">邮箱</div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" v-model="datas.email" :rules="[{validator:validatorEmail, message: '请输入正确的邮箱'}]"/>

                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">被调查人员上级姓名</div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" v-model="datas.sup_name"/>

                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">职位</div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" v-model="datas.sup_position"/>

                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">电话</div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" v-model="datas.sup_mobile" :rules="[{validator:validatorMobile, message: '请输入正确的电话'}]"/>

                <van-cell :border="false">
                    <template #title>
                        <div class="custom-title font-16">邮箱</div>
                    </template>
                </van-cell>
                <van-field class="contacts-field-input" v-model="datas.sup_email" :rules="[{validator:validatorEmail, message: '请输入正确的邮箱'}]"/>
                <div class="height-20"></div>
                <!-- <van-button type="info" class="send-btn" :disabled="disabled" block native-type="submit">发送</van-button> -->
                <div class="send-btn t-center">
                    <van-button type="info" class="btn-prl m-r-10" :disabled="disabled || datas.can == 0" native-type="submit">发送</van-button>
                    <van-button type="info" class="btn-mrl" :disabled="disabled || datas.can == 0" @click="special()">特殊情况申请</van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>
<script>
import {project,save} from "@/api/user";
import {ticket, shareTitle, shareUrl, shareImg, shareDesc, commonShare} from "@/api/wxshare";
import $ from  'jquery'
export default {
    name:'Contacts',
    data() {
        return {
            params:this.$route.params,
            datas:{},
            disabled:false,
        }
    },
    created() {
        let _this = this;
        _this.updateShare();
        _this.getProject();
    },
    methods: {
        async updateShare() {
            let _this = this;
            const res = await ticket({url:window.location.href});
            if (res.code == 1) {
                commonShare(res.data, shareTitle, window.location.href, shareImg, shareDesc)
            }
        },
        validatorMobile(val) {
            if (!val) return true;
            return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|19[0-9]|18[0-9]|14[57])[0-9]{8}$/.test(val);
        },
        validatorEmail(val) {
            if (!val) return true;
            return /^([a-zA-Z0-9_-_.])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/.test(val);
        },
        async getProject() {
            let _this = this;
            const res = await project({id:_this.params.id});
            if (res.code == 1) {
                _this.datas = res.data;
            }
        },
        special() {
            let _this = this;
            window.location.href = '/contacts/special/'+_this.params.id;
        },
        onClickLeft() {
            history.go(-1);
        },
        // onClickRight() {
        //     let _this = this;
        //     $('#form_submit').click();
        // },
        async onSubmit() {
            let _this = this;
            _this.disabled = true;
            const res = await save({id:_this.params.id,datas:_this.datas});
            if (res.code == 1) {
                this.$toast.setDefaultOptions({ duration: 1000 });
                this.$toast.success(res.msg);
                setTimeout(ress=> {
                    this.$router.push({
                        path:'/'
                    })
                }, 1000); 
            } else {
                _this.disabled = false;
            }
        },
    },
}    
</script>